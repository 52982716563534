import React  from "react"
import ReactHtmlParser from "react-html-parser"

import styled from "styled-components"

const Text = styled.div`
max-width: 50%;
margin: 0 auto;
padding-bottom: 2rem;
@media screen and (max-width: 600px) { 
    max-width: 100%;
    }
    p {
        font-weight: 300;
    }
`

const Content = ({text}) => {
    return (
        <section>
             <Text> {ReactHtmlParser( text )} </Text>
        </section>
    )
}

export default Content