import React, { useState } from "react"

import FluidImage from "../../components/FluidImage"
import ReactHtmlParser from "react-html-parser"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./gallery.scss"

import styled from "styled-components"

const Section = styled.div`
    display: block;
    width. 100%;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 3rem;
    .medium {
        width: 75%;
        margin: 0 auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .small {
        width: 50%;
        margin: 0 auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .big {
        .bigger {
            padding-bottom: 100%;
        }
    }
`

const Container = styled.div`
  width: 100%;
  height: calc(100% + 50px);
  position: relative;
`

const AspectRatio = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  position: relative;
  @media screen and (max-width: 600px) {
    padding-bottom: 80%;
  }
`

const AspectRatioInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Caption = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 20px;
  margin-top: 2rem;
  p {
    font-family: minion-pro-display, serif;
    text-align: center;
    font-size: 0.8rem;
    line-height: 0.9rem;
  }
`

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  centerMode: true,
  centerPadding: 0,
  fade: true,
}

const Gallery = ({ choose, images }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const openLightbox = index => {
    setPhotoIndex(index)
    setLightboxOpen(true)
  }

  const closeLightbox = () => {
    setLightboxOpen(false)
  }

  console.log(images[photoIndex].imageFile.childImageSharp.fluid.src)

  return (
    <Section>
      <div className={choose}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <Container key={index}>
              <AspectRatio
                className="bigger"
                onClick={() => openLightbox(index)}
              >
                <AspectRatioInner>
                  <FluidImage image={image} objectPosition="center center" />
                </AspectRatioInner>
              </AspectRatio>
              <Caption>{ReactHtmlParser(image.caption)}</Caption>
            </Container>
          ))}
        </Slider>
      </div>
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex].imageFile.childImageSharp.fluid.src}
          nextSrc={
            images[(photoIndex + 1) % images.length].imageFile.childImageSharp
              .fluid.src
          }
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length].imageFile
              .childImageSharp.fluid.src
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </Section>
  )
}

export default Gallery
