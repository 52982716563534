import React  from "react"

import Layout from "../../components/layout"
import AllLayouts from "../../components/AllLayouts"
import Pagination from "../../components/pagination"
import SEO from "../../components/seo"

import styled from "styled-components"

const Section = styled.div`
  padding-top:50px;
  max-width: 80vw;
  margin: 0 auto;
  @media screen and (max-width: 600px) {
    padding-top: 100px;
  }
`
const Main = styled.div`
  width: 100%;
  height: 100%;
  h1 {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    display: table;
    width: auto;
    margin: 0 auto;
    padding: 0.5rem 0.5rem 0.8rem 0.5rem;
    margin-bottom: 2rem;
  }
`

const Post = ({ pageContext }) => {
  const {
    post: { title, pageBuilder },
  } = pageContext

  const prev = pageContext.prev
  ? {
        url: `${pageContext.prev.uri}`,
     }
  : null 

  const next = pageContext.next
  ? {
        url: `${pageContext.next.uri}`,
     }
  : null

  const layouts = pageBuilder.build || []

  return (
    <Layout>
      <SEO title={title} />
      <Pagination next={next} prev={prev}></Pagination> 
      <Section>
        <Main>
          <h1> {title} </h1>
          {
            layouts.map((layout, index) => {
              return <AllLayouts key={index} layoutData={layout} />
            })
          }
        
        </Main>
      </Section>
    </Layout>
  )
}

export default Post