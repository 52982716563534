import React from "react"
import Content from "../layouts/Content"
import Gallery from "../layouts/Gallery"
import Video from "../layouts/Video"

const AllLayouts = ({ layoutData }) => {

  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => <div>In AllLayouts the mapping of this component is missing: {layoutType}</div>

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    project_Pagebuilder_Build_Gallery : Gallery,
    project_Pagebuilder_Build_Content: Content,
    project_Pagebuilder_Build_Video: Video,
    page_default: Default
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']

  return (
    <ComponentTag {...layoutData} />
  )
}

export default AllLayouts