import React  from "react"
import ReactPlayer from 'react-player'

import styled from "styled-components"

const Section = styled.div`
    display: block;
    width. 100%;
    height: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    .medium {
        width: 75%;
        margin: 0 auto;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
    .small {
        width: 59%;
        margin: 0 auto;
        @media screen and (max-width: 600px) {
            width: 75%;
        }
    }
`

const EmebdContainer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width 100%;
    height: auto;
    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
`

const Video = ({choose, src}) => {
    return (
        <Section>
            <div className={choose}>
            <EmebdContainer>
                <ReactPlayer url={src} />
            </EmebdContainer>
            </div>
        </Section>
    )
}

export default Video