import React  from "react"

import { Link } from "gatsby"

import HomeIcon from "../assets/home.svg"
import PrevIcon from "../assets/prev.svg"
import NextIcon from "../assets/next.svg"

import styled from "styled-components"

const MainNav = styled.div`
    position: fixed;
    display: flex;
    justify-content: start;
    width: 200px;
    top: 2rem;
    right: 0;
    z-index: 9999;
`

const Home = styled.div`
         width: 50px;
         display: flex;
`

const Prev = styled.div`
         width: 50px;
         display: flex;
`
const Next = styled.div`
         width: 50px;
         display: flex;
         margin-right: 10px;
`

class Pagination extends React.Component {
    render () {
    return (
       <MainNav>


         <Next>
            {this.props.next && (
               <Link to={this.props.next.url}>
                  <NextIcon />
               </Link>
            )} 
         </Next>

         <Prev>
         {this.props.prev && (
            <Link to={this.props.prev.url}>
               <PrevIcon />
            </Link>
         )}
         </Prev>

         <Home><Link to="/projects"><HomeIcon /></Link></Home>
       </MainNav>
      )
    }
}
  
  export default Pagination